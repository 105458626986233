<template>
  <v-app>
    <div v-if="this.signedIn" class="viewWordsContent">

      <div id="nav">
        <router-link id="backToAdmin" to="/admin">&larr; back to Admin</router-link> |
        <router-link to="/nouns">Nouns</router-link> |
        <router-link to="/sentencesnouns">Sentences &mdash; Nouns</router-link> |
        <router-link to="/adjectives">Adjectives</router-link> |
        <router-link to="/sentencesadjectives">Sentences &mdash; Adjectives</router-link>
      </div>


      <v-expansion-panels>
        <v-expansion-panel style="margin: 10px 0">
          <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
            <h3>Instructions</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div style="text-align:center;margin:15px 0 10px 0; padding:20px;border: 2px solid #343a40; border-radius:20px;">
              <p style="">'English' field must contain a single '_' character where the missing noun fits, and it must make grammatical sense in English.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="">'Russian' field must contain a single '_' character where the missing noun fits, and it must make grammatical sense in Russian.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="">The 'Nouns' field contains English nouns that fit both the translation and the Russian parts.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="margin:0;">'Nouns' must be separated by a ',' comma, and not contain any spaces.</p>
              <p style="margin:0;">E.g. If the 'English' field is "I see _", "tree" is NOT a valid noun, as it doesn't make grammatical sense in English, only Russian.</p>
              <p style="margin:0;">In such instances, simply create a new sentence with the 'English' field, "I see a _". The 'Russian' field can be the same, this is fine.</p>

            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h1>Add Sentences (for nouns)</h1>

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Nouns</v-chip>
            </v-col>
            <v-col cols="1">

            </v-col>
          </v-row>
        </v-card>


          <v-card style="border-color: #fff;box-shadow: none; text-align:center">
            <v-row>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" ref="englishTranslationRef" v-model="sentenceEnglishField" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="sentenceRussianField" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" :class="getBackgroundColour(sentenceCaseRequiredField)" v-model="sentenceCaseRequiredField" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="sentenceWordsField" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" elevation=0 color="green lighten-3" @click="addSentence()">Add</v-btn>
              </v-col>

            </v-row>
          </v-card>







      <v-row class="mt-16">

        <v-col cols="8">
          <h1>View Sentences (for nouns)</h1>
        </v-col>


        <v-col cols="4">
          <v-text-field
              outlined
              placeholder="Search by Noun"
              autocomplete="off"
              v-model="sentenceSearchBar"
              @input="search()"
              :hint="searchedSentences.length + ' ' + this.searchedResultTag + ' found'"
            ></v-text-field>
        </v-col>

      </v-row>

<!-- Searched -->
      <div v-if="searchedSentences.length > 0">
        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English </v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Nouns</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Sentence in searchedSentences" :key="Sentence.id">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.english" :value="Sentence.english" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.russian" :value="Sentence.english" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" v-model="Sentence.caseRequired" :class="getBackgroundColour(Sentence.caseRequired)" :value="Sentence.caseRequired" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.words" :value="Sentence.words" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" icon color="#e63946" @click="deleteSentence(Sentence.id)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>

                <v-btn :disabled="inputsDisabled" icon color="#90be6d" @click="updateSentence(Sentence)">
                  <v-icon> mdi-check-circle </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>

        </transition-group>
      </div>


<!-- Pagination -->

      <div v-if="Sentences.length > 0 && searchedSentences.length == 0">
        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English </v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Nouns</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Sentence in Sentences" :key="Sentence.id">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.english" :value="Sentence.english" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.russian" :value="Sentence.english" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" v-model="Sentence.caseRequired" :class="getBackgroundColour(Sentence.caseRequired)" :value="Sentence.caseRequired" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.words" :value="Sentence.words" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" icon color="#e63946" @click="deleteSentence(Sentence.id)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>

                <v-btn :disabled="inputsDisabled" icon color="#90be6d" @click="updateSentence(Sentence)">
                  <v-icon> mdi-check-circle </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>

        </transition-group>

        <template>
          <div class="text-center">
            <v-pagination
              color="#ffb703"
              v-model="page"
              :length="numberOfPages"
              @input="updatePagination()"
            ></v-pagination>
          </div>
        </template>
      </div>



    </div>
  </v-app>
</template>


<style media="screen">
  .v-application--wrap{
    min-height: 0;
  }


  .navigation a:current {
    color: blue;
  }

  h1{
    margin: 0 0 20px 0;
  }

  .viewWordsContent {
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 0;
    padding: 20px;
    /* padding-top: 0; */
    text-align: left;
  }

  .text-english {
    background-color: #ffb703;
    font-weight: bold;
  }

  .colour-nom {
    background-color: #403d39;
  }
  .colour-acc {
    background-color: #ffbe0b;
  }
  .colour-prep {
    background-color: #00b4d8;
  }
  .colour-gen {
    background-color: #e73265;
  }
  .colour-dat {
    background-color: #90be6d;
  }
  .colour-inst {
    background-color: #ada7c9;
  }

  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #fff !important;
    font-weight: bold;
  }


</style>










<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

export default {
  title: "Sentences - Nouns",
  data() {
    return {
      signedIn: false,
      page: 1,
      pageLimit: 10,
      numberOfPages: 1,
      words: [],
      wordsSelected: [],
      searchedSentences: [],
      sentenceSearchBar: "",
      searchedResultTag: "results",
      stats: [],

      Field: [],
      Sentences: [],
      SentencesTotal: [],
      newItem: "",
      cases: ['nom', 'gen', 'acc', 'dat', 'inst', 'prep'],
      sentenceRussianField: "",
      sentencePart2Field: "",
      sentenceCaseRequiredField: "",
      sentenceWordsField: [],
      sentenceEnglishField: "",
      inputsDisabled: false
    }
  },
  methods: {

    async search() {
      console.log(this.sentenceSearchBar);

      await db.collection("SentencesNouns").where("words", "array-contains", this.sentenceSearchBar)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {

              var sentence = doc.data();
              sentence.id = doc.id;
              this.searchedSentences.push(sentence)

            })
          }
        })

        for (var i = this.searchedSentences.length - 1; i >= 0; i--) {
          if (! this.searchedSentences[i].words.includes(this.sentenceSearchBar)) {
              this.searchedSentences.splice(i, 1);
          }
        }

        this.searchedResultTag = (this.searchedSentences.length == 1) ? "result" : "results"


    },




    async updatePagination() {

      this.Sentences = []




      var totalLength = (this.SentencesTotal.length % this.pageLimit == 1) ? this.SentencesTotal.length + 1 : this.SentencesTotal.length

      console.log(this.SentencesTotal.length % this.pageLimit);




      if ((this.SentencesTotal.length % this.pageLimit != 0) && this.page == this.numberOfPages) {

        var remainder = this.SentencesTotal.length % this.pageLimit
        this.Sentences = this.SentencesTotal.slice(Math.max(this.SentencesTotal.length - remainder, 0))


        console.log("LAST: " + this.Sentences);

      } else if ((this.pageLimit * this.page) <= totalLength) {

        console.log("HELLO!!!!!!!");

                  if (this.SentencesTotal[(this.pageLimit * (this.page - 1))] == "NULL") {

                    console.log("array component: " + (this.pageLimit * (this.page - 1)));

                    var indextemp = (this.pageLimit * (this.page - 1))

                    for (var i = 0; i < this.pageLimit; i++) {
                      await db.collection("SentencesNouns").orderBy("sentenceID").startAt(indextemp).limit(1)
                        .get()
                        .then((querySnapshot) => {
                          if (!querySnapshot.empty) {
                            querySnapshot.forEach((doc) => {

                              var sentence = doc.data();
                              sentence.id = doc.id
                              this.SentencesTotal[indextemp] = sentence

                            })
                          }
                        })

                        indextemp++
                    }

                  }

                  var arrayComponent1 = (this.pageLimit * (this.page - 1))
                  var counter1 = 0;

                  while (counter1 < this.pageLimit) {
                    if (this.SentencesTotal[arrayComponent1 + counter1] == null) {
                      break;
                    } else {
                      this.Sentences.push(this.SentencesTotal[arrayComponent1 + counter1])
                      counter1++
                    }
                  }

      } else {

        console.log("SentencesTotal length:  " + this.SentencesTotal.length);
        console.log((this.page - 1 * this.pageLimit));

        while (this.SentencesTotal.length < ((this.page - 1) * this.pageLimit)) {
          this.SentencesTotal.push("NULL")
        }

        console.log(this.SentencesTotal);

        var temp = ((this.page - 1) * this.pageLimit)

        await db.collection("SentencesNouns").orderBy("sentenceID").startAt(temp).limit(this.pageLimit)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {

                var sentence = doc.data();

                sentence.id = doc.id
                this.SentencesTotal.push(sentence)

              })
            }
          })



          var arrayComponent = ((this.page - 1) * this.pageLimit)
          var counter = 0;

          while (counter < this.pageLimit) {
            if (this.SentencesTotal[arrayComponent + counter] == null) {
              break;
            }
            this.Sentences.push(this.SentencesTotal[arrayComponent + counter])
            counter++
          }

      }


      console.log("Words length:  " + this.Sentences.length);
      console.log(this.Sentences);
      console.log("---");
      console.log("Words Total length:  " + this.SentencesTotal.length);
      console.log(this.SentencesTotal);


    },


    getBackgroundColour(caseAbbreviation) {
      var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst']
      var colourClasses = ['colour-nom', 'colour-acc', 'colour-prep', 'colour-gen', 'colour-dat', 'colour-inst']

      return colourClasses[caseAbbreviations.indexOf(caseAbbreviation)]

    },






    ensureWordsAreArray(){
      console.log(this.sentenceWordsField);

      //var wordsArray
      if (! Array.isArray(this.sentenceWordsField) && this.sentenceWordsField != null) {
        this.sentenceWordsField = this.sentenceWordsField.split(',');
      } else if (this.sentenceWordsField == null || this.sentenceWordsField == "") {
        this.sentenceWordsField = []
      } else {
        //wordsArray = this.sentenceWordsField;
      }

      console.log(this.sentenceWordsField);

    },



    toggleAllInputs() {
      this.inputsDisabled = !this.inputsDisabled;

      return this.inputsDisabled;
    },



    //+-----------------------
    //| addSentence()
    //|
    //| ensure words are in array format
    //| get Statistics
    //| if all fields are valid
    //|     add sentence
    //|     increment numberOfSentences
    //|     get doc ID of sentence just added
    //|
    //|     for each word
    //|       If word does not exist -> create incomplete word,
    //|         add the sentenceID to each in [case]sentences
    //|         increment newlyAddedWords
    //|       If word already exists
    //|         increment casePossibilities, numberOfCasePoss (if necessary)
    //|         add sentenceID to [case]Sentences
    //|         If numberOfCasePoss >= 3
    //|           ensure the word is in Task 2 array for each case with length > 0
    //|             increment Task2Count if updated.
    //|
    //|       Update Statistics
    //|         numberOfNouns = numberOfNouns + newlyAddedWords
    //|         Task2Counts
    //+-----------------------


    async addSentence() {

      this.toggleAllInputs();

      await this.ensureWordsAreArray();

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesNouns = stats.numberOfSentencesNouns;
      // var numberOfNouns = stats.numberOfNouns;
      var task2countsMasc = stats.task2countsMasc;
      var task2countsFem = stats.task2countsFem;
      var task2countsNeut = stats.task2countsNeut;
      var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
      console.log(stats);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| If all fields are valid
      //+------------------
      if (this.sentenceRussianField != ""
          && this.sentenceEnglishTranslationField != "" && this.sentenceCaseRequiredField != ""
          && this.sentenceWordsField != "") {

            console.log("valid");
            console.log(this.sentenceRussianField);
            console.log(this.sentenceEnglishField);

            //+------------------
            //| Add sentence
            //+------------------
            await db.collection("SentencesNouns").add({
              russian: this.sentenceRussianField,
              english: this.sentenceEnglishField,
              caseRequired: this.sentenceCaseRequiredField,
              words: this.sentenceWordsField,
              sentenceID: numberOfSentencesNouns
            })

            numberOfSentencesNouns++;

            console.log("~~~~~~~~~~~");
            console.log("Added sentence");
            console.log("~~~~~~~~~~~");

            //+------------------
            //| Get doc id of sentence just added
            //+------------------

            //this currently causing an error, because there are gaps / errors in the id order.
            //need to edit delete -- so it sets the last sentence (with highest id) to the id of the sentence being deleted. So there are no gaps.
            //this way, every time you add a sentence, it always adds with the latest id. This also affects words -> same issue, also fix this.
            var sentence, sentenceID;
            await db.collection("SentencesNouns").where("sentenceID", "==", (numberOfSentencesNouns - 1))
              .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    sentence = doc.data();
                    sentenceID = doc.id;
                  })
                })
            console.log(sentence);
            console.log("Sentence ID: " + sentenceID);
            console.log("~~~~~~~~~~~");

            // var newlyAddedWords = 0;


            for (var i = 0; i < sentence.words.length; i++) {

              var wordExists = false;
              var word, wordID;
              await db.collection("Nouns").where("english", "==", sentence.words[i]).limit(1)
                .get()
                .then((querySnapshot) => {
                  if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                      wordExists = true;
                      word = doc.data();
                      wordID = doc.id;
                    })
                  }
                })
              var caseSentences = sentence.caseRequired + "Sentences";

              if (!wordExists) {
                //+------------------
                //| Create incomplete word
                //|   add sentenceID to [case]Sentneces
                //|   increment newlyAddedWords
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Creating new word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                await db.collection("Nouns").add({
                  english: sentence.words[i],
                  incomplete: true,
                  "nom": '',
                  "gen": '',
                  "acc": '',
                  "dat": '',
                  "inst": '',
                  "prep": '',
                  "nomSentences": [],
                  "genSentences": [],
                  "accSentences": [],
                  "datSentences": [],
                  "instSentences": [],
                  "prepSentences": [],
                  "gender": '',
                  "quantity": '',
                  "numberOfCasePossibilities": 1,
                  "casePossibilities": [sentence.caseRequired],
                  [caseSentences]: [sentenceID]
                });
                // newlyAddedWords++;

              } else {
                //+------------------
                //| Word already exists
                //|   adjust casePossibilities, numberOfCasePossibilities (IF NECESSARY)
                //|   add sentenceID to [case]Sentneces
                //|   if (numberOfCasePossibilities >= 3)
                //|       ensure the word is in Task2 collections for each caseSentences with length > 0
                //|         increment Task2 count if updated
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Amending existing word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                if (word[caseSentences].length == 0) {
                  word.numberOfCasePossibilities++;
                  word.casePossibilities.push(sentence.caseRequired);
                }

                if (!word[caseSentences].includes(sentenceID)) {
                  word[caseSentences].push(sentenceID);
                }

                await db.collection("Nouns").doc(wordID).update({
                  [caseSentences]: word[caseSentences],
                  numberOfCasePossibilities: word.numberOfCasePossibilities,
                  casePossibilities: word.casePossibilities
                });


                var caseSentenceArrayNames = ['nomSentences', 'genSentences', 'accSentences', 'datSentences', 'instSentences', 'prepSentences']
                var task2CaseCollectionNames = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
                var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']

                var task2GenderCollection = (word.gender) == 'm' ? 'MascTask2' : 'FemTask2';
                if (word.gender == 'n') {
                  task2GenderCollection = 'NeutTask2';
                }
                // arrayLevel --> task2Counts = ['m', 'f', 'n']
                var arrayLevel = (word.gender) == 'm' ? 0 : 1;
                if (word.gender == 'n') {
                  arrayLevel = 2;
                }

                if (word.numberOfCasePossibilities == 3 && word.incomplete == false) {
                  //add 3 task 2 documents

                  console.log("~~~~~~~~~~~");
                  console.log("Create 3 new Task2 docs");
                  console.log("~~~~~~~~~~~");

                  for (var j = 0; j < caseAbbreviations.length; j++) {
                    if (word.casePossibilities.includes(caseAbbreviations[j])) {

                      await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[j]).doc(wordID).set({
                        "countID" : task2Counts[arrayLevel][j],
                        "english" : word.english
                      })
                      task2Counts[arrayLevel][j]++

                    }
                  }

                } else if (word.numberOfCasePossibilities > 3 && word.incomplete == false) {
                  //add just the one extra task 2 document

                  console.log("~~~~~~~~~~~");
                  console.log("Add 1 extra Task 2 doc");
                  console.log("~~~~~~~~~~~");

                  var caseIndex = caseSentenceArrayNames.indexOf(caseSentences)

                  await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[caseIndex]).doc(wordID).set({
                    "countID" : task2Counts[arrayLevel][caseIndex],
                    "english" : word.english
                  })
                  task2Counts[arrayLevel][caseIndex]++

                }


              }



            } //end for loop (for each word)

            //+------------------
            //| Update stats
            //|   numberOfNouns = numberOfNouns + newlyAddedWords
            //|   Task2 counts
            //+------------------
            await db.collection('Statistics').doc('STATISTICS').update({
              // numberOfNouns: numberOfNouns + newlyAddedWords,
              numberOfSentencesNouns: numberOfSentencesNouns,
              task2countsMasc: task2Counts[0],
              task2countsFem: task2Counts[1],
              task2countsNeut: task2Counts[2]
            })

            console.log("~~~~~~~~~~~");
            console.log("Stats updated");
            console.log("~~~~~~~~~~~");

            //+----------------------------
            //| Sets fields to blank.
            //+----------------------------
            this.sentenceRussianField = "";
            this.sentenceEnglishField = "";
            this.sentenceCaseRequiredField = "";
            this.sentenceWordsField = [];
            this.$refs.englishTranslationRef.focus();



          } else {
            alert("Complete all fields")
          } //end of if (all entered fields valid)

          this.toggleAllInputs();



    },







    //+-----------------------
    //| updateSentence()
    //|
    //| get Statistics
    //| fetch Sentence
    //| compare fetchedSentence with updatedSentence
    //| loop through words -> remove words not in updatedSentence; add words not in fetchedSentence
    //| if caseRequired different
    //|   need to remove sentenceID from old [case]Sentences, and add to new [case]Sentences
    //|   also change casePossibilites (if old [case]Sentences.length now == 0 for each word)
    //|   this also potentially impacts numberOfCasePossibilities
    //| if numberOfCasePossibilities is now < 3
    //|   remove all from Task2 collections for this word
    //| else remove from the old Task2Case collection -> to the new one, as it should still be numberOfCasePossibilities >= 3
    //| make sure to adjust counts
    //| update sentence with new data
    //| update Stats
    //+-----------------------

    //This might actually be the less read intensive option:
    //Lazy way: just runs delete and then modified add sentence.

    //But remember to set the new id the same as the old id. So it doesn't jump in the view page.
    async updateSentence(sentence) {

      console.log(sentence);

      console.log("WAIT");

      var sentenceID = await this.updateDeleteSentence(sentence.id);

      await this.updateAddSentence(sentence, sentenceID);

      console.log(sentenceID);

      console.log("DONE!!!!!");

    },



    async updateDeleteSentence(sentenceID) {

      this.toggleAllInputs();

      console.log(sentenceID);

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesNouns = stats.numberOfSentencesNouns;
      var task2countsMasc = stats.task2countsMasc;
      var task2countsFem = stats.task2countsFem;
      var task2countsNeut = stats.task2countsNeut;
      var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
      console.log(stats);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Fetch sentence
      //+------------------
      var sentence = await db.collection('SentencesNouns').doc(sentenceID).get();
      sentence = sentence.data();
      console.log(sentence);

      console.log("~~~~~~~~~~~");

      var fetchedWords = sentence.words;
      console.log(fetchedWords);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Loop through sentence words
      //|   remove sentenceID
      //|   amend word.casePossibilities; word.numberOfCasePossibilities if required
      //|   remove from all Task2 collections if word.numberOfCasePossibilities now below 3
      //|     or just remove from one Task2 collection if word[sentence.caseRequired + "Sentences"].length is now 0
      //+------------------
      for (var i = 0; i < fetchedWords.length; i++) {
        var word, wordID;
        var wordExists = false;
        await db.collection("Nouns").where("english", "==", fetchedWords[i])
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                wordExists = true;
                word = doc.data();
                wordID = doc.id;
                })
              }
            })
        console.log(word);

        if (wordExists) {


          console.log("case poss: " + word.casePossibilities);
          console.log("no cas po: " + word.numberOfCasePossibilities);

          //+------------------
          //| Remove sentenceID from the word's caseSentences array
          //+------------------
          var caseSentencesArray = sentence.caseRequired + "Sentences"
          caseSentencesArray = word[caseSentencesArray]
          console.log(caseSentencesArray);
          var index = caseSentencesArray.indexOf(sentenceID)
          if (index > -1) {
            caseSentencesArray.splice(index, 1)
          }
          console.log(caseSentencesArray);


          //+------------------
          //| If after removing sentenceID, the '[case]Sentences' array length now == 0; need to
          //|   decrement numberOfCasePossibilities; remove case from casePossibilities
          //|   remove all, or just this case from Task2 collections
          //+------------------
          if (caseSentencesArray.length == 0) {
            word.numberOfCasePossibilities--;

            var collectionGenderArrays = ['MascTask2', 'FemTask2', 'NeutTask2']
            var collectionCaseArrays = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
            var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']
            var tempGenders = ['m', 'f', 'n']
            var collectionGender = collectionGenderArrays[tempGenders.indexOf(word.gender)]
            console.log("collectionGender: " + collectionGender);

            //+------------------
            //| It would only have been in Task2Collections if it was >=3; so no need to cycle through if it was originally 2, 1, 0
            //+------------------
            if (word.numberOfCasePossibilities == 2 && word.incomplete == false) {
              //+------------------
              //| Remove all instances of this word from Task2 collections
              //| Decrement Task2 counts
              //+------------------
              console.log("No. of case poss: " + word.numberOfCasePossibilities);

              for (var j = 0; j < collectionCaseArrays.length; j++) {

                if (word.casePossibilities.includes(caseAbbreviations[j])) {
                  await db.collection("Task2").doc("Stats").collection(collectionGender)
                                              .doc("Stats").collection(collectionCaseArrays[j])
                                              .doc(wordID)
                                              .delete();
                  console.log("Deleted... " + caseAbbreviations[j]);

                  task2Counts[tempGenders.indexOf(word.gender)][j]--;
                }

              }



            } else if (word.numberOfCasePossibilities >= 3 && word.incomplete == false) {
              //+------------------
              //| Remove this one instance from Task2 collections
              //| Decrement Task2 count
              //+------------------
              console.log("ELSE No. of case poss: " + word.numberOfCasePossibilities);

              var caseCollection = collectionCaseArrays[caseAbbreviations.indexOf(sentence.caseRequired)]

              await db.collection("Task2").doc("Stats").collection(collectionGender)
                                          .doc("Stats").collection(caseCollection)
                                          .doc(wordID)
                                          .delete();
              // console.log("~Deleted... " + i);
              task2Counts[tempGenders.indexOf(word.gender)][caseAbbreviations.indexOf(sentence.caseRequired)]--;

            }

            //+------------------
            //| Now the case can be removed from casePossibilities
            //+------------------
            index = word.casePossibilities.indexOf(sentence.caseRequired)
            if (index > -1) {
              word.casePossibilities.splice(index, 1)
            }


          } //end of if ([case]Sentences.length == 0)

          console.log("~~~~~~~~~~~");

          console.log(caseSentencesArray);
          console.log(word.casePossibilities);
          console.log("no cas po: " + word.numberOfCasePossibilities);

          //+------------------
          //| Update the word
          //+------------------
          await db.collection('Nouns').doc(wordID).update({
            [sentence.caseRequired + "Sentences"]: caseSentencesArray,
            casePossibilities: word.casePossibilities,
            numberOfCasePossibilities: word.numberOfCasePossibilities
          })

        }


      } //end of for loop (cycle through words)

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Delete the sentence, decrement sentence count
      //+------------------
      await db.collection("SentencesNouns").doc(sentenceID).delete()
      numberOfSentencesNouns--;
      console.log("Decremented sentences, now: " + numberOfSentencesNouns);




      //+------------------
      //| Update stats
      //+------------------
      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfSentencesNouns: numberOfSentencesNouns,
        task2countsMasc: task2Counts[0],
        task2countsFem: task2Counts[1],
        task2countsNeut: task2Counts[2]
      })

      console.log("~~~~~~~~~~~");


      this.toggleAllInputs();

      return sentence.sentenceID;


    },



    async updateAddSentence(sentence, sentenceIncrementalID) {

      this.toggleAllInputs();

      // await this.ensureWordsAreArray();

      if (! Array.isArray(sentence.words) && sentence.words != null) {
        sentence.words = sentence.words.split(',');
      } else if (sentence.words == null || sentence.words == "") {
        sentence.words = []
      } else {
        //wordsArray = this.sentenceWordsField;
      }

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesNouns = stats.numberOfSentencesNouns;
      var numberOfNouns = stats.numberOfNouns;
      var task2countsMasc = stats.task2countsMasc;
      var task2countsFem = stats.task2countsFem;
      var task2countsNeut = stats.task2countsNeut;
      var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
      console.log(stats);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| If all fields are valid
      //+------------------
      if (sentence.russian != ""
          && sentence.english != "" && sentence.caseRequired != ""
          && sentence.words != "") {

            //+------------------
            //| Add sentence
            //+------------------
            await db.collection("SentencesNouns").doc(sentence.id).set({
              russian: sentence.russian,
              english: sentence.english,
              caseRequired: sentence.caseRequired,
              words: sentence.words,
              sentenceID: sentenceIncrementalID
            })

            numberOfSentencesNouns++;

            console.log("~~~~~~~~~~~");
            console.log("Added sentence");
            console.log("~~~~~~~~~~~");

            //+------------------
            //| Get doc id of sentence just added
            //+------------------

            //this currently causing an error, because there are gaps / errors in the id order.
            //need to edit delete -- so it sets the last sentence (with highest id) to the id of the sentence being deleted. So there are no gaps.
            //this way, every time you add a sentence, it always adds with the latest id. This also affects words -> same issue, also fix this.
            // var sentenceID;
            // await db.collection("Sentences").where("id", "==", (numberOfSentences - 1))
            //   .get()
            //   .then((querySnapshot) => {
            //       querySnapshot.forEach((doc) => {
            //         sentence = doc.data();
            //         sentenceID = doc.id;
            //       })
            //     })
            // console.log(sentence);
            // console.log("Sentence ID: " + sentenceID);
            // console.log("~~~~~~~~~~~");

            var newlyAddedWords = 0;


            for (var i = 0; i < sentence.words.length; i++) {

              var wordExists = false;
              var word, wordID;
              await db.collection("Nouns").where("english", "==", sentence.words[i]).limit(1)
                .get()
                .then((querySnapshot) => {
                  if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                      wordExists = true;
                      word = doc.data();
                      wordID = doc.id;
                    })
                  }
                })
              var caseSentences = sentence.caseRequired + "Sentences";

              if (!wordExists) {
                //+------------------
                //| Create incomplete word
                //|   add sentenceID to [case]Sentneces
                //|   increment newlyAddedWords
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Creating new word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                await db.collection("Nouns").add({
                  english: sentence.words[i],
                  incomplete: true,
                  "nom": '',
                  "gen": '',
                  "acc": '',
                  "dat": '',
                  "inst": '',
                  "prep": '',
                  "nomSentences": [],
                  "genSentences": [],
                  "accSentences": [],
                  "datSentences": [],
                  "instSentences": [],
                  "prepSentences": [],
                  "gender": '',
                  "quantity": '',
                  "numberOfCasePossibilities": 1,
                  "casePossibilities": [sentence.caseRequired],
                  [caseSentences]: [sentence.id]
                });
                newlyAddedWords++;

              } else {
                //+------------------
                //| Word already exists
                //|   adjust casePossibilities, numberOfCasePossibilities (IF NECESSARY)
                //|   add sentenceID to [case]Sentneces
                //|   if (numberOfCasePossibilities >= 3)
                //|       ensure the word is in Task2 collections for each caseSentences with length > 0
                //|         increment Task2 count if updated
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Amending existing word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                if (word[caseSentences].length == 0) {
                  word.numberOfCasePossibilities++;
                  word.casePossibilities.push(sentence.caseRequired);
                }

                if (!word[caseSentences].includes(sentence.id)) {
                  word[caseSentences].push(sentence.id);
                }

                await db.collection("Nouns").doc(wordID).update({
                  [caseSentences]: word[caseSentences],
                  numberOfCasePossibilities: word.numberOfCasePossibilities,
                  casePossibilities: word.casePossibilities
                });


                var caseSentenceArrayNames = ['nomSentences', 'genSentences', 'accSentences', 'datSentences', 'instSentences', 'prepSentences']
                var task2CaseCollectionNames = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
                var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']

                var task2GenderCollection = (word.gender) == 'm' ? 'MascTask2' : 'FemTask2';
                if (word.gender == 'n') {
                  task2GenderCollection = 'NeutTask2';
                }
                // arrayLevel --> task2Counts = ['m', 'f', 'n']
                var arrayLevel = (word.gender) == 'm' ? 0 : 1;
                if (word.gender == 'n') {
                  arrayLevel = 2;
                }

                console.log("±±±±±±±±±±±±±±±±±±±±±±±");
                console.log("±±±±±±±±±±±±±±±±±±±±±±±");
                console.log("±±±±±±±±±±±±±±±±±±±±±±±");
                console.log("No. c. p. : " + word.numberOfCasePossibilities);
                console.log("Array lvl : " + arrayLevel);
                console.log("±±±±±±±±±±±±±±±±±±±±±±±");
                console.log("±±±±±±±±±±±±±±±±±±±±±±±");
                console.log("±±±±±±±±±±±±±±±±±±±±±±±");

                if (word.numberOfCasePossibilities == 3 && word.incomplete == false) {
                  //add 3 task 2 documents

                  console.log("~~~~~~~~~~~");
                  console.log("Create 3 new Task2 docs");
                  console.log("~~~~~~~~~~~");

                  for (var j = 0; j < caseAbbreviations.length; j++) {
                    if (word.casePossibilities.includes(caseAbbreviations[j])) {

                      console.log("||||||");
                      console.log(task2Counts);
                      console.log("Array lvl : " + arrayLevel);
                      console.log("j         : " + j);
                      console.log(task2Counts[arrayLevel][j]);
                      console.log("||||||");

                      await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[j]).doc(wordID).set({
                        "countID" : task2Counts[arrayLevel][j],
                        "english" : word.english
                      })
                      task2Counts[arrayLevel][j]++
                      console.log("}}}}}}}}}}}}}}}}");
                      console.log(task2Counts);
                      console.log("{{{{{{{{}}}}}}}}");

                    }
                  }

                } else if (word.numberOfCasePossibilities > 3 && word.incomplete == false) {
                  //add just the one extra task 2 document

                  console.log("~~~~~~~~~~~");
                  console.log("Add 1 extra Task 2 doc");
                  console.log("~~~~~~~~~~~");

                  var caseIndex = caseSentenceArrayNames.indexOf(caseSentences)

                  await db.collection("Task2").doc("Stats").collection(task2GenderCollection).doc("Stats").collection(task2CaseCollectionNames[caseIndex]).doc(wordID).set({
                    "countID" : task2Counts[arrayLevel][caseIndex],
                    "english" : word.english
                  })
                  task2Counts[arrayLevel][caseIndex]++

                }


              }



            } //end for loop (for each word)

            //+------------------
            //| Update stats
            //|   numberOfNouns = numberOfNouns + newlyAddedWords
            //|   Task2 counts
            //+------------------
            await db.collection('Statistics').doc('STATISTICS').update({
              numberOfNouns: numberOfNouns + newlyAddedWords,
              numberOfSentencesNouns: numberOfSentencesNouns,
              task2countsMasc: task2Counts[0],
              task2countsFem: task2Counts[1],
              task2countsNeut: task2Counts[2]
            })

            console.log(task2Counts);

            console.log("~~~~~~~~~~~");
            console.log("Stats updated");
            console.log("~~~~~~~~~~~");





          } else {
            alert("Complete all fields")
          } //end of if (all entered fields valid)

          this.toggleAllInputs();


    },








    //+-----------------------
    //| deleteSentence(id)
    //|
    //| get Statistics
    //| get sentence document from id parameter
    //|
    //| for each word from fetched words attribute
    //|   get the word
    //|     remove sentenceID from the word's [case]Sentences
    //|       If [case]Sentences.length is now == 0
    //|         remove from casePossibilities; decrement numberOfCasePoss
    //|       If numberOfCasePoss < 3
    //|         Remove all instances of this word from Task2Collection
    //|         Decrement Task2Counts
    //|       Else
    //|         Just remove this one instance from Task2Collection
    //|         Decrement Task2Count
    //|
    //| Delete the sentence
    //| Decrement numberOfSentences
    //| Update Stats -> numberOfSentences; task2Counts
    //|
    //+-----------------------


    //Status Thursday 8am: Seems to be totally working.
    //Possible issue, that I think is solved in this method but need to check for others -> incomplete words don't have gender yet
    //So DO NOT add to Task2 collections automatically if they get >= 3 casePossibilities
    //Only when incomplete becomes false, then check casePossibilities and add to Task2 collections after this, if necessary.

    // ^ NEED TO DO THIS IN WORDS.

    async deleteSentence(sentenceID) {

      this.toggleAllInputs();

      console.log(sentenceID);

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesNouns = stats.numberOfSentencesNouns;
      var task2countsMasc = stats.task2countsMasc;
      var task2countsFem = stats.task2countsFem;
      var task2countsNeut = stats.task2countsNeut;
      var task2Counts = [task2countsMasc, task2countsFem, task2countsNeut];
      console.log(stats);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Fetch sentence
      //+------------------
      var sentence = await db.collection('SentencesNouns').doc(sentenceID).get();
      sentence = sentence.data();
      console.log(sentence);

      console.log("~~~~~~~~~~~");

      var fetchedWords = sentence.words;
      console.log(fetchedWords);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Loop through sentence words
      //|   remove sentenceID
      //|   amend word.casePossibilities; word.numberOfCasePossibilities if required
      //|   remove from all Task2 collections if word.numberOfCasePossibilities now below 3
      //|     or just remove from one Task2 collection if word[sentence.caseRequired + "Sentences"].length is now 0
      //+------------------
      for (var i = 0; i < fetchedWords.length; i++) {
        var word, wordID;
        var wordExists = false;
        await db.collection("Nouns").where("english", "==", fetchedWords[i])
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                wordExists = true;
                word = doc.data();
                wordID = doc.id;
                })
              }
            })
        console.log(word);

        if (wordExists) {


          console.log("case poss: " + word.casePossibilities);
          console.log("no cas po: " + word.numberOfCasePossibilities);

          //+------------------
          //| Remove sentenceID from the word's caseSentences array
          //+------------------
          var caseSentencesArray = sentence.caseRequired + "Sentences"
          caseSentencesArray = word[caseSentencesArray]
          console.log(caseSentencesArray);
          var index = caseSentencesArray.indexOf(sentenceID)
          if (index > -1) {
            caseSentencesArray.splice(index, 1)
          }
          console.log(caseSentencesArray);


          //+------------------
          //| If after removing sentenceID, the '[case]Sentences' array length now == 0; need to
          //|   decrement numberOfCasePossibilities; remove case from casePossibilities
          //|   remove all, or just this case from Task2 collections
          //+------------------
          if (caseSentencesArray.length == 0) {
            word.numberOfCasePossibilities--;

            var collectionGenderArrays = ['MascTask2', 'FemTask2', 'NeutTask2']
            var collectionCaseArrays = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
            var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']
            var tempGenders = ['m', 'f', 'n']
            var collectionGender = collectionGenderArrays[tempGenders.indexOf(word.gender)]
            console.log("collectionGender: " + collectionGender);

            //+------------------
            //| It would only have been in Task2Collections if it was >=3; so no need to cycle through if it was originally 2, 1, 0
            //+------------------
            if (word.numberOfCasePossibilities == 2 && word.incomplete == false) {
              //+------------------
              //| Remove all instances of this word from Task2 collections
              //| Decrement Task2 counts
              //+------------------
              console.log("No. of case poss: " + word.numberOfCasePossibilities);

              for (var j = 0; j < collectionCaseArrays.length; j++) {

                if (word.casePossibilities.includes(caseAbbreviations[j])) {
                  await db.collection("Task2").doc("Stats").collection(collectionGender)
                                              .doc("Stats").collection(collectionCaseArrays[j])
                                              .doc(wordID)
                                              .delete();
                  console.log("Deleted... " + caseAbbreviations[j]);

                  task2Counts[tempGenders.indexOf(word.gender)][j]--;
                }

              }



            } else if (word.numberOfCasePossibilities >= 3 && word.incomplete == false) {
              //+------------------
              //| Remove this one instance from Task2 collections
              //| Decrement Task2 count
              //+------------------
              console.log("ELSE No. of case poss: " + word.numberOfCasePossibilities);

              var caseCollection = collectionCaseArrays[caseAbbreviations.indexOf(sentence.caseRequired)]

              await db.collection("Task2").doc("Stats").collection(collectionGender)
                                          .doc("Stats").collection(caseCollection)
                                          .doc(wordID)
                                          .delete();
              // console.log("~Deleted... " + i);
              task2Counts[tempGenders.indexOf(word.gender)][caseAbbreviations.indexOf(sentence.caseRequired)]--;

            }

            //+------------------
            //| Now the case can be removed from casePossibilities
            //+------------------
            index = word.casePossibilities.indexOf(sentence.caseRequired)
            if (index > -1) {
              word.casePossibilities.splice(index, 1)
            }


          } //end of if ([case]Sentences.length == 0)

          console.log("~~~~~~~~~~~");

          console.log(caseSentencesArray);
          console.log(word.casePossibilities);
          console.log("no cas po: " + word.numberOfCasePossibilities);

          //+------------------
          //| Update the word
          //+------------------
          await db.collection('Nouns').doc(wordID).update({
            [sentence.caseRequired + "Sentences"]: caseSentencesArray,
            casePossibilities: word.casePossibilities,
            numberOfCasePossibilities: word.numberOfCasePossibilities
          })

        }


      } //end of for loop (cycle through words)

      console.log("~~~~~~~~~~~");

      //+------------------
      //| Delete the sentence, decrement sentence count
      //+------------------
      await db.collection("SentencesNouns").doc(sentenceID).delete()
      numberOfSentencesNouns--;
      console.log("Decremented sentences, now: " + numberOfSentencesNouns);


      //+------------------
      //| To ensure no gaps in incremental id sequence, set sentence with 'last' id to the id of the sentence just deleted.
      //| (no need to do this if deleting the last added one)
      //+------------------
      if (sentence.sentenceID != numberOfSentencesNouns) {
        console.log("NOT EQUAL");
        await db.collection("SentencesNouns").where("sentenceID", "==", numberOfSentencesNouns)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                console.log(wordID);
                wordID = doc.id;
                })
              }
            })

        await db.collection('SentencesNouns').doc(wordID).update({
          sentenceID: sentence.sentenceID
        })
      }

      //+------------------
      //| Update stats
      //+------------------
      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfSentencesNouns: numberOfSentencesNouns,
        task2countsMasc: task2Counts[0],
        task2countsFem: task2Counts[1],
        task2countsNeut: task2Counts[2]
      })

      console.log("~~~~~~~~~~~");


      this.toggleAllInputs();


    },
















































    updateSentenceWords(){
      var temp = this.sentenceWordsField
      console.info('updated combo:', temp);

      this.wordsSelected.push(temp);
    },


    async checkIfAdmin() {
      var user = await db.collection("Users").doc(this.uid).get();
      user = user.data();

      if (!user.admin) {
        this.$router.push({ name: 'Index'})
      }
      // return user.admin;
    }




  },
  firestore: {
    // Sentences: db.collection("SentencesNouns")
  },
  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.signedIn = true;
        this.username = user.displayName;

        this.checkIfAdmin(user.uid);


      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });


    // if (this.uid == '') {
    //   this.$router.push('/');
    // }


    // firebase.firestore.FieldValue.delete()

    // var counter = 0
    //
    await db.collection("SentencesNouns")
    .orderBy("sentenceID")
    // .where("caseRequired", "==", "dat")
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {

            var doc2 = doc.data();

            // console.log(doc2.sentenceID + " | " + doc.id + " | " + doc2.english);
            console.log(doc2.sentenceID + " | " + doc2.english);


          })
        }
      })
    //
    //   await db.collection("Statistics").doc("STATISTICS").update({
    //     numberOfNouns: counter
    //   });




    this.stats = await db.collection("Statistics").doc("STATISTICS").get();
    this.stats = this.stats.data();

    // var numberOfNouns = this.stats.numberOfNouns - this.stats.numberOfMasculineNouns - this.stats.numberOfFeminineNouns - this.stats.numberOfNeuterNouns;

    this.numberOfPages = Math.ceil((this.stats.numberOfSentencesNouns) / this.pageLimit);

    // console.log(this.stats);

    // this.IncompleteWords = await db.collection("Nouns").where("incomplete", "==", true).limit(1).get();
    // this.IncompleteWords = this.IncompleteWords.data();

    await db.collection("SentencesNouns").orderBy("sentenceID").startAt(0).limit(this.pageLimit)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {

            var sentence = doc.data();

            sentence.id = doc.id;

            this.Sentences.push(sentence)
            this.SentencesTotal.push(sentence)
            // var wordID = doc.id;

            console.log(sentence);




          })
        }
      })





    console.log("~~~~");
    console.log("~~~~");
    console.log(this.Sentences);
    console.log("~~~~");
    console.log("~~~~");






  },
  async created() {
    //This is potentially going to be very read-costly. Think when you have 100s, 1000s of words. Each time -> lots and lots of reads.
    //It will be better, when adding new words, also to append to an array in Statistics collection, so you can just read in one document that contains an array of every English word.
    //Rather than having to read in every single word document.
        // await db.collection("Words")
        //  .get()
        //  .then((querySnapshot) => {
        //    if (querySnapshot.empty) {
        //      console.log("found no docs")
        //    } else {
        //
        //      querySnapshot.forEach((doc) => {
        //
        //        this.words.push(doc.data().english);
        //
        //      });
        //
        //    }
        //  })
        //  .catch((error) => {
        //      console.log("Error getting documents: ", error);
        //  });


  },
}
</script>










<!--  -->
